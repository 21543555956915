import React, { useState, useRef, useCallback, useEffect } from 'react'
import {
  useParams,
  usePrevious,
  sortAsc,
  sortDesc,
  clampRange,
  formatOption,
  useFilterProducts,
} from '@lib/helpers'

import { useSiteContext } from '@lib/context'

import ProductCard from '@components/product-card'
import BlockContent from '@components/block-content'
import FilterAccordions from '@components/filter-accordions'
import Filters from '@components/filters'
import FiltersButton from '@components/filters-button'

const Collection = ({ page }) => {
  const {
    products,
    sorting,
    filtering,
    style,
    styles,
    colors,
    artists,
    paginationLimit,
    noFilterResults,
  } = page || {}

  if (!products || products.length === 0) return null

  const collectionItems = useRef([])
  const { isPageTransition } = useSiteContext()
  const [hasPagination, setHasPagination] = useState(
    paginationLimit > 0 && products.length > paginationLimit
  )
  const [currentCount, setCurrentCount] = useState(
    hasPagination ? paginationLimit : products.length
  )

  const [currentParams, setCurrentParams] = useParams([
    {
      name: 'page',
      value: null,
    },
    {
      name: 'q',
      value: null,
    },
    {
      name: 'sort',
      value: sorting,
    },
    {
      name: 'styles',
      value: null,
    },
    {
      name: 'colors',
      value: null,
    },
    {
      name: 'artists',
      value: null,
    },
    {
      name: 'sizes',
      value: null,
    },
  ])
  const previousParams = usePrevious(currentParams)

  // determine which params set to use
  const activeParams =
    isPageTransition && previousParams ? previousParams : currentParams

  // calculate our sort
  const activeSort = activeParams.find((filter) => filter.name === 'sort').value

  // calculate our sort
  const activeSearch = activeParams.find((filter) => filter.name === 'q').value

  // calculate our filters
  const currentFilters = activeParams.filter((f) => {
    return f.values !== null && !['page', 'sort'].includes(f.name)
  })

  const filters = {
    styles,
    colors,
    artists,
    sizes: products
      .map((p) =>
        p?.options
          .find((o) => o.name === 'Size')
          ?.values.map((v) => v.split(' ')[0])
      )
      .flat()
      .filter((value, i, self) => value && self.indexOf(value) === i)
      .map((value) => ({
        title: formatOption({ name: 'Size', value }),
        slug: value,
      }))
      .sort((a, b) =>
        a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
      ),
  }

  const activeFilters = currentFilters.map(({ name, value }) => {
    const currentOptions = Array.isArray(value)
      ? value
      : value?.split(',') || []
    const validOptions = filters[name]?.map((s) => s.slug)

    return {
      name,
      values: [
        ...new Set(
          currentOptions
            .filter(Boolean)
            .filter((f) => validOptions?.includes(f))
        ),
      ],
    }
  })

  // calculate our product order and pagination
  const orderedProducts = useFilterAndSort(
    products
      .filter((p) => !p.hideOnIndex)
      .map((p) => {
        // Find and show cheapest price
        const cheapest =
          (p.variants.length > 0
            ? p.variants.sort((a, b) => (a.price > b.price ? 1 : -1))
            : p.variants)[0] || p

        // Find the biggest discount
        const discounts = p.variants
          .filter((v) => v.price < v.comparePrice)
          .map((v) => {
            return ((v.comparePrice - v.price) / v.comparePrice) * 100
          })
          .sort()
          .reverse()

        return {
          ...p,
          price: cheapest.price,
          discounted: discounts.length > 0 ? discounts[0] : null,
        }
      })
      .filter((p) => (filtering === 'discounted' ? p.discounted : true)),
    activeFilters,
    activeSort,
    activeSearch,
    style
  )

  const paginatedProducts = [...orderedProducts.slice(0, currentCount)]

  // handle filter + sort updates
  const updateParams = useCallback(
    (params) => {
      const newFilters = activeParams.map((filter) => {
        const matchedParam = params?.find((p) => p.name === filter.name)
        return matchedParam ? { ...filter, value: matchedParam?.value } : filter
      })
      setCurrentParams(newFilters)
    },
    [activeParams]
  )

  const loadMoreBarRef = useRef(null)

  // handle load more
  const loadMore = useCallback(() => {
    const newCount = clampRange(
      currentCount + paginationLimit,
      1,
      orderedProducts.length
    )

    const newPage = Math.ceil(newCount / paginationLimit)

    setCurrentCount(newCount)
    updateParams([{ name: 'page', value: `${newPage > 1 ? newPage : null}` }])
  }, [currentCount, orderedProducts, paginationLimit])

  const clearFilters = (key) => {
    let params = []

    if (key) {
      // Reset a single filters
      params = [
        {
          name: key,
          value: null,
        },
      ]
    } else {
      // Reset all filters
      params = activeFilters.map((filter) => ({
        name: filter.name,
        value: null,
      }))
    }

    updateParams(params)
  }

  // update pagination when the count or products change
  useEffect(() => {
    const desiredPage = activeParams.find((p) => p.name === 'page').value
    const maxPage = Math.ceil(orderedProducts.length / paginationLimit)

    const newCount =
      desiredPage > 1 && desiredPage <= maxPage
        ? clampRange(paginationLimit * desiredPage, 1, orderedProducts.length)
        : null

    const pageProductIndex =
      newCount < orderedProducts?.length
        ? newCount - paginationLimit
        : orderedProducts.length - 1

    if (newCount) {
      setCurrentCount(newCount)
      collectionItems.current[pageProductIndex]?.querySelector('[href]').focus({
        preventScroll: true,
      })
    }

    setHasPagination(currentCount < orderedProducts.length)
  }, [
    currentCount,
    orderedProducts,
    activeParams,
    paginationLimit,
    collectionItems,
  ])

  useEffect(() => {
    document.body.classList.add('is-collection')
    return () => document.body.classList.remove('is-collection')
  }, [])

  useEffect(() => {
    if (!loadMoreBarRef.current) return
    let observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && hasPagination) loadMore()
        })
      },
      {
        rootMargin: '0px 0px -20% 0px',
        threshold: 1,
      }
    )
    observer.observe(loadMoreBarRef.current)
    return () => observer.disconnect()
  }, [loadMore, hasPagination])

  return (
    <>
      <div className="collection">
        <div className="sidebar is-sticky">
          <FilterAccordions
            page={page}
            orderedProducts={orderedProducts}
            filters={filters}
            activeSort={activeSort}
            activeParams={activeParams}
            currentParams={currentParams}
            updateParams={updateParams}
            clearFilters={clearFilters}
          />
        </div>
        <div className="flex-grow">
          <FiltersButton />
          <div className="collection--products">
            {paginatedProducts.map((product, key) => (
              <ProductCard key={key} product={product} />
            ))}
          </div>
          <div className="w-full h-4" ref={loadMoreBarRef}></div>
          {orderedProducts.length === 0 && (
            <div className="collection--empty">
              {noFilterResults && <BlockContent blocks={noFilterResults} />}
              <button
                className="filters-reset btn is-large mt-5"
                onClick={clearFilters}
              >
                Clear Filters
              </button>
            </div>
          )}
        </div>
      </div>

      <Filters>
        <FilterAccordions
          page={page}
          orderedProducts={orderedProducts}
          filters={filters}
          activeSort={activeSort}
          activeParams={activeParams}
          currentParams={currentParams}
          updateParams={updateParams}
          clearFilters={clearFilters}
        />
      </Filters>
    </>
  )
}

const useFilterAndSort = (products, filters, sort, search, style) => {
  const filteredProducts = useFilterProducts({
    products,
    filters,
    search,
    style,
  })

  switch (sort) {
    case 'priceAsc':
      return sortAsc(filteredProducts, 'price')
    case 'priceDesc':
      return sortDesc(filteredProducts, 'price')
    case 'alphaAsc':
      return sortAsc(filteredProducts, 'title')
    case 'alphaDesc':
      return sortDesc(filteredProducts, 'title')
    case 'dateAsc':
      return sortAsc(filteredProducts, 'publishDate')
    case 'dateDesc':
      return sortDesc(filteredProducts, 'publishDate')
    case 'discounted': {
      return sortDesc(filteredProducts, 'discounted')
    }
    default:
      return sortAsc(
        filteredProducts.map((p) => ({
          ...p,
          order: Number.isInteger(p.order)
            ? p.order
            : filteredProducts.length * 2,
        })),
        'order'
      )
  }
}

export default Collection

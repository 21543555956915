import React from 'react'

import Listbox from '@components/listbox'
import Icon from './icon'

const CollectionSort = ({ sortOptions, activeSort, onChange }) => {
  return (
    <div className="collection-sort">
      <Listbox
        id="collection-sort"
        label="Select sorting order"
        name="sort"
        options={sortOptions}
        activeOption={activeSort}
        onChange={onChange}
        after={<Icon name="ChevronDown" className="w-3 h-3 ml-3" />}
      />
    </div>
  )
}

export default CollectionSort

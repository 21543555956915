import React, { useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { m } from 'framer-motion'
import cx from 'classnames'

import { useSiteContext, useToggleFilters } from '@lib/context'
import Icon from './icon'

const Filters = ({ children }) => {
  const { isFiltersOpen } = useSiteContext()
  const toggleFilters = useToggleFilters()

  const [hasFocus, setHasFocus] = useState(false)

  const handleKeyDown = (e) => {
    if (e.which === 27) {
      toggleFilters(false)
    }
  }

  return (
    <>
      <FocusTrap
        active={isFiltersOpen && hasFocus}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <m.div
          initial="hide"
          animate={isFiltersOpen ? 'show' : 'hide'}
          variants={{
            show: {
              x: '0%',
            },
            hide: {
              x: '-100%',
            },
          }}
          transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
          onKeyDown={(e) => handleKeyDown(e)}
          onAnimationComplete={(v) => setHasFocus(v === 'show')}
          className={cx('filters', {
            'is-active': isFiltersOpen,
          })}
        >
          <div className="filters--inner">
            <div className="filters--header">
              <div className="filters--title">Filter</div>
              <button
                className="btn is-close"
                onClick={() => toggleFilters(false)}
              >
                <Icon name="Cross" className="w-5" />
              </button>
            </div>
            <div className="filters--content">{children}</div>
            <div className="filters--footer">
              <button
                onClick={(e) => toggleFilters(false)}
                className="btn is-primary is-large is-center"
              >
                Apply
              </button>
            </div>
          </div>
        </m.div>
      </FocusTrap>

      <div
        className={cx('backdrop', {
          'is-active': isFiltersOpen,
        })}
        onClick={() => toggleFilters(false)}
      />
    </>
  )
}

export default Filters

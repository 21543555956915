import React, { useState, useRef, useEffect } from 'react'
import cx from 'classnames'

import { useToggleFilters } from '@lib/context'
import Icon from './icon'

const FiltersButton = () => {
  const toggleFilters = useToggleFilters()
  const buttonRef = useRef()
  const [isOutsideViewport, setIsOutsideViewport] = useState()

  const showFilters = () => {
    toggleFilters(true)
  }

  const handleScroll = () => {
    if (buttonRef.current) {
      const { top, height } = buttonRef.current.getBoundingClientRect()
      setIsOutsideViewport(top < -height)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <>
      <button
        className="btn is-outlined collection-button w-1/2 mb-8 justify-between md:hidden"
        onClick={showFilters}
        ref={buttonRef}
      >
        Filter <Icon name="ChevronRight" className="w-4" />
      </button>
      <button
        className={cx('btn is-primary is-floating', {
          'is-visible': isOutsideViewport,
        })}
        onClick={showFilters}
      >
        Filter <Icon name="ChevronRight" className="w-4 ml-5" color="white" />
      </button>
    </>
  )
}

export default FiltersButton

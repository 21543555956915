import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import CollectionSort from '@components/collection-sort'
import Accordion from '@components/accordion'
import Icon from '@components/icon'

const Filters = ({
  page,
  filters,
  activeSort,
  activeParams,
  updateParams,
  currentParams,
  clearFilters,
  orderedProducts,
}) => {
  const { type, products, sortOptions, style } = page
  const [accordions, setAccordions] = useState([])

  const [productsFilters, setProductsFilters] = useState([])

  const onToggle = (id, open) =>
    setAccordions((arr) =>
      arr.map((a) => (a.id === id ? { ...a, open } : a))
    )

  const isChecked = (key, slug) =>
    activeParams.find((p) => p.name === key)?.value?.indexOf(slug) > -1

  const isDisabled = (item) =>
    !productsFilters.some((p) => p.some((f) => f === item.slug))

  const onChangeFilter = (e, key) => {
    const { name, checked } = e.currentTarget
    let activeFilters = activeParams
      .find((p) => p.name === key)
      .value?.split(',')

    activeFilters = checked
      ? [...(activeFilters || []), name]
      : activeFilters.filter((s) => s !== name)

    updateParams([
      {
        name: key,
        value: activeFilters.length > 0 ? activeFilters.join(',') : null,
      },
    ])
  }

  useEffect(() => {
    setProductsFilters(
      orderedProducts.map((product) => {
        return [
          ...(product.styles || [])?.map((s) => s?.slug),
          ...(product.colors || [])?.map((c) => c?.slug),
          ...(product.artist ? [product.artist.slug] : []),
          ...product.options
            .filter((o) => o.name === 'Size')
            .map((s) => s.values.map((v) => v.split(' ')[0]))
            .flat(),
        ]
      })
    )
  }, [orderedProducts])

  useEffect(() => {
    if (products.length > 0) {
      setAccordions([
        !style &&
          products.some((p) => p.styles?.length > 0) && {
            id: 'styles',
            title: 'Style',
            open: true,
          },
        { id: 'colors', title: 'Colour', open: true },
        type !== 'artist' &&
          products.some((p) => p.artist) && {
            id: 'artists',
            title: 'Artist',
            open: true,
          },
        products.some((p) =>
          p.options.find((o) => o.name === 'Size' && o.values.length > 1)
        ) && {
          id: 'sizes',
          title: 'Size',
          open: true,
        },
      ])
    }
  }, [products])

  return (
    <>
      <div className="border-b border-gray-20 py-4 pt-2 flex justify-between font-medium">
        Sort by
        <div className="relative text-right pr-2 flex items-center">
          <CollectionSort
            sortOptions={sortOptions}
            activeSort={activeSort}
            onChange={updateParams}
          />
        </div>
      </div>
      {accordions.map((acc) => {
        if (!acc?.id) return null
        const filtersAmount =
          currentParams.find((p) => p.name === acc.id).value?.split(',')
            ?.length || 0

        return (
          <Accordion
            key={acc.id}
            id={acc.id}
            title={acc.title}
            isOpen={acc.open}
            onToggle={onToggle}
            className="relative"
            after={
              filtersAmount > 0 && (
                <button
                  className="btn is-reset"
                  onClick={() => clearFilters(acc.id)}
                >
                  {filtersAmount}
                  <Icon name="FatCross" />
                </button>
              )
            }
          >
            <ul>
              {filters[acc.id]?.map((filter, key) => {
                const checked = isChecked(acc.id, filter.slug)
                const disabled = isDisabled(filter, checked, acc)

                return (
                  <li key={key}>
                    <label
                      className={cx('switch flex justify-between py-2', {
                        'text-gray': disabled,
                      })}
                    >
                      {filter.title}
                      <span className="switch--container">
                        <input
                          type="checkbox"
                          name={filter.slug}
                          onChange={(e) => onChangeFilter(e, acc.id)}
                          checked={checked}
                          disabled={disabled}
                        />
                        <span className="switch--slider is-round" />
                      </span>
                    </label>
                  </li>
                )
              })}
            </ul>
          </Accordion>
        )
      })}
    </>
  )
}

export default Filters
